<template>
  <div class="vg_wrapper">
    <add-header
        @submit="submit('psotForm')"
        @cancel="cancel">
    </add-header>
    <el-form ref="psotForm" :rules="rules" :model="psotForm" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="出库编号" prop="psot_no">
            <el-input v-model="psotForm.psot_no" maxlength="10"  show-word-limit placeholder="请填写出库编号">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="出库时间" prop="psot_time">
            <el-date-picker
              v-model="psotForm.psot_time"
              type="date"
              placeholder="选择出库时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vg_button">
        <el-button type="primary" plain size="small" @click="addRow(0)">导入成品待出库</el-button>
        <el-button type="primary" plain size="small" @click="addRow(1)">导入产品</el-button>
        <el-button type="danger" plain size="small" @click="delBank()">删除</el-button>
      </div>
      <el-row class="vd_mrt">
        <PsotAddEditIn @handleSelectionChange="handleSelect" :psotForm="psotForm"></PsotAddEditIn>
      </el-row>
      <el-dialog
          :title="title"
          :visible.sync="notice"
          width="70%">
        <PsotAddEditPpot @confirmIn="confirmIn" ref="PsotAddEditPpot"></PsotAddEditPpot>
      </el-dialog>
      <el-dialog
          :title="title"
          :visible.sync="notice1"
          width="70%">
        <PsotAddEditProd @confirmIn="confirmIn" ref="PsotAddEditProd"></PsotAddEditProd>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import addHeader from "@/views/component/addHeader";
import inputUser from "@/views/component/inputUser";
import PsotAddEditPpot from "@/views/StockManagement/PsotManage/TabChild/Component/PsotAddEditPpot";
import PsotAddEditProd from "@/views/StockManagement/PsotManage/TabChild/Component/PsotAddEditProd";
import PsotAddEditIn from "@/views/StockManagement/PsotManage/TabChild/Component/TabPanel/PsotAddEditIn";
import {psotAPI} from "@api/modules/psot";
export default {
  name: "PsotAddMain",
  components: {
    addHeader,
    inputUser,
    PsotAddEditIn,
    PsotAddEditPpot,
    PsotAddEditProd,
  },
  data(){
    return{
      psotForm:{
        psot_no:'',
        psot_time:'',
        psot_prod_list:[],
        key:0,
      },
      rules:{
        psot_no:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        psot_time:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
      },
      removeList:[],
      psotList:[],
      notice:false,
      title:'',
      notice1:false,
    }
  },
  created() {
  },
  methods:{
    //提交表单
    submit(formName){
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            return false;
          }
        });
      }).catch(()=>{})
    },
    //保存
    saveInfo() {
      const staffForm =  this.$refs.userData.getData()
      let psotForm = {};
      psotForm = Object.assign(psotForm,this.psotForm,staffForm);
      psotForm.psot_time = (new Date(psotForm.psot_time)).getTime() / 1000;
      post(psotAPI.addPsot,psotForm)
          .then(res => {
            if(res.data.code === 0){
              this.$message({
                type:'success',
                message:'保存成功'
              })
              const permId = this.$route.query.perm_id
              this.jump(`/psot_edit?perm_id=${permId}&form_id=${res.data.data.form_id}`)
              this.resetForm('psotForm')
            }else if(res.data.code === 7){
              this.$message({
                type:'error',
                message:'单据编号重复'
              })
              this.psotForm.psot_date = (new Date(this.psotForm.psot_date)).getTime() * 1000;
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
              this.psotForm.psot_date = (new Date(this.psotForm.psot_date)).getTime() * 1000;
            }
          }).catch(res =>{
            let msg = res.data.msg 
            this.$message.error(msg)
          })
    },
    //取消
    cancel(){
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetForm('psotForm')
        this.$router.go(-1)
        this.$message({
          type: 'info',
          message: '已取消新增!'
        });
      }).catch(()=>{})
    },
    //删除选择的内容
    handleSelect(val){
      this.removeList = val;
    },
    // 删除物料信息按钮
    delBank(){
      for(let i=0;i<this.removeList.length;i++){
        if(this.psotForm.psot_prod_list.indexOf(this.removeList[i]) !== -1){
          let index = this.psotForm.psot_prod_list.indexOf(this.removeList[i]);
          this.psotForm.psot_prod_list.splice(index,1)
        }
      }
    },
    // 打开导入物料信息小窗口
    addRow(num){
      if(num === 0){
        this.notice = true;
      }else if(num === 1){
        this.notice1 = true;
      }
    },
    // 导入小窗口确定按钮
    confirmIn(val){
      this.notice = false;
      this.notice1 = false;
      if(val.length>0){
        this.psotList = val;
        let psotListCopy = [];
        for(let i=0;i<this.psotList.length;i++){
          let temp = {};
          if(this.psotList[i].ppot_id){
            temp.ppot_id = this.psotList[i].ppot_id;
            temp.stay_ppot_num = parseInt(this.psotList[i].pcon_prod_num)-parseInt(this.psotList[i].ppot_num);
          }
          temp.prod_id = this.psotList[i].prod_id;
          temp.prod_no = this.psotList[i].prod_no;
          temp.prod_name = this.psotList[i].prod_name;
          temp.prod_spec = this.psotList[i].prod_spec;
          temp.prod_type = this.psotList[i].prod_type;
          psotListCopy.push(temp)
        }
        this.psotForm.psot_prod_list = this.psotForm.psot_prod_list.concat(psotListCopy);
        if(this.psotList[0].ppot_id){
          this.$refs.PsotAddEditPpot.clear()
        }else{
          this.$refs.PsotAddEditProd.clear()
        }
      }
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.psotForm.psot_prod_list = [];
    },
  }
}
</script>

<style scoped lang="scss">
.vd_mrt{
  margin-top: 20px;
}
.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}
</style>